import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import SpotlightEvent from "../components/events/spotlight"
import MoreEvents from "../components/events/more_events"
import AboutCommb from "../components/events/about_commb"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { INLINES } from "@contentful/rich-text-types"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node) => {
      return (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {node.content[0].value}
        </a>
      )
    },
    'paragraph': (node, children) => <p>{children}</p>,
    'text': (node) => {
      if (node.marks && node.marks[0] && node.marks[0].type === 'bold') {
        return <strong>{node.value}</strong>;
      }
      return node.value.split('\n').reduce((acc, text, i) => (
        i === 0 ? [text] : [...acc, <br key={i} />, text]
      ), []);
    },
  },
};

const Events = ({ data }) => (
  <div className="eventsPage industryEvents">
    <Layout>
      <Seo title="Events" />
      <div className="events_page">
        <div className="main_content_wrapper insight_page">
          <div className="spotlight_article_section newEventsMainSec">
            <div className={data.contentfulEvents.hiddenTestField ? "container spotlightSec hideSpotlight" : "container spotlightSec"}>
              <div className="article_block">
                <div className="article_image_block">
                  <a href={data.contentfulEvents.spotlightEvent.externalInternalEvent} target="_blank" style={{display: 'contents'}}>
                    <GatsbyImage
                      image={getImage(data.contentfulEvents.spotlightEvent.image)}
                      alt="Featured article"
                      />
                  </a>
                </div>
                <div className="article_info_block common_inner_title newEventsSpotlightWrapper">
                  <h3 style={{ color: "#fff" }}>
                    <a href={data.contentfulEvents.spotlightEvent.externalInternalEvent} target="_blank" className="eventSpotlightTitle">
                      {data.contentfulEvents.spotlightEvent.title}
                    </a>
                  </h3>
                  {data.contentfulEvents.spotlightEvent.excerptNew && (
                    <div>
                      {renderRichText(data.contentfulEvents.spotlightEvent.excerptNew, renderOptions)}
                    </div>
                  )}
                  <div className="common_section_btn common_btn newEventsSpotlightBtn">
                    <a href={data.contentfulEvents.spotlightEvent.externalInternalEvent} target="_blank" className="eventSpotlightTitle">
                      Learn More
                      <svg xmlns="http://www.w3.org/2000/svg" width="38.938" height="8.071" viewBox="0 0 38.938 8.071"><g id="right-arrow" transform="translate(-1192.844 -692.433)"><path id="Icon_open-arrow-left" data-name="Icon open-arrow-left" d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z" transform="translate(1231 699.427) rotate(180)" fill="#bf1116"></path><path id="Icon_open-arrow-left_-_Outline" data-name="Icon open-arrow-left - Outline" d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z" transform="translate(1231 699.427) rotate(180)" fill="#bf1116"></path></g></svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AboutCommb content={data.contentfulEvents} />
        </div>
      </div>
    </Layout>
  </div>
)

export default Events

export const query = graphql`
  query AboutPageQuery($language: String) {
    contentfulEvents(node_locale: { eq: $language }) {
      section3Title
      section3ShortText
      spotlightEvent {
        title
        image {
          gatsbyImageData
        }
        excerpt {
          excerpt
        }
        excerptNew {
          raw
        }
        externalInternalEvent
      }
      section3Content {
        raw
      }
      section3Url
      section3Button
      section3ImageBackground {
        gatsbyImageData
      }
      section3ImageFront {
        gatsbyImageData
      }
      section3ImageShapes {
        file {
          url
        }
      }
    }
  }
`
