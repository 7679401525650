import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const AboutCommb = ({ content }) => {
  const {
    section3Title,
    section3ShortText,
    section3Content,
    section3Url,
    section3Button,
    section3ImageBackground,
    section3ImageFront,
    section3ImageShapes,
  } = content

  return (
    <div className="about_commb_section">
      <div className="about_commb_image_block">
        <GatsbyImage
          image={getImage(section3ImageBackground)}
          alt="about image"
        />

        <div className="about_small_img">
          <GatsbyImage image={getImage(section3ImageFront)} alt="About Img" />
        </div>

        <div className="about_plus_shape_img">
          <img src={section3ImageShapes.file.url} alt="shape" />
        </div>
      </div>

      <div className="about_commb_content">
        <div className="container">
          <div className="about_commb_details">
            <div className="commn_title_label">
              <p>{section3ShortText}</p>
            </div>
            <div className="common_inner_title">
              <h3>{section3Title}</h3>
            </div>
            <div className="common_desc">{renderRichText(section3Content)}</div>
              <a href={section3Url} className="eventsCTABtn">
              {section3Button}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.938"
                  height="8.071"
                  viewBox="0 0 38.938 8.071"
                >
                  <g transform="translate(-1192.844 -692.433)">
                    <path
                      data-name="Icon open-arrow-left"
                      d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                      transform="translate(1231 699.427) rotate(180)"
                      fill="#e15b5f"
                    ></path>
                    <path
                      data-name="Icon open-arrow-left - Outline"
                      d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                      transform="translate(1231 699.427) rotate(180)"
                      fill="#e15b5f"
                    ></path>
                  </g>
                </svg>
              </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutCommb
